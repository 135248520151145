import React from 'react';
import { graphql } from 'gatsby';

import Page from '../components/Page';
import Content from '../components/Content';
import SEO from '../components/SEO';

function PrivacyPolicy({ data }) {
  const page = data.site.edges[0].node.privacyPolicyPage;
  const {
    title,
    metaDescription,
    metaImage: { sizes: { src: metaImage } = {} } = {},
    content: { json } = {}
  } = page;
  const body = (
    <>
      <SEO
        title={title}
        description={metaDescription && metaDescription.metaDescription}
        image={metaImage}
      />
      <Content json={json} />
    </>
  );

  return <Page title={title} body={body} />;
}

export default PrivacyPolicy;

export const query = graphql`
  query PrivacyPolicyQuery {
    site: allContentfulSite(limit: 1) {
      edges {
        node {
          privacyPolicyPage {
            title
            metaDescription {
              metaDescription
            }
            metaImage {
              sizes {
                src
              }
            }
            content {
              json
            }
          }
        }
      }
    }
  }
`;
